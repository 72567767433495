import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

StandardButton.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  onClick: PropTypes.func,
  standardPadding: PropTypes.bool,
  onlyIcon: PropTypes.bool,
};
StandardButton.defaultProps = {
  className: "",
  iconClassName: "",
  textClassName: "",
  onClick: () => {},
  standardPadding: true,
  onlyIcon: false,
};

function StandardButton(props) {
  return (
    <button
      className={`standardButton ${
        props.standardPadding ? "standardButton--standardPadding" : ""
      } ${props.className} `}
      onClick={props.onClick}
    >
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon}
          className={`${props.iconClassName} ${
            !props.onlyIcon ? "mr-2" : ""
          } standardButton__icon`}
        />
      )}
      {!props.onlyIcon && (
        <span className={`${props.textClassName} standardButton__text`}>
          {props.children}
        </span>
      )}
    </button>
  );
}

export default StandardButton;
