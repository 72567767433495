import React, { useRef } from "react";
import Style from "./titantron.module.scss";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import StandardHeadline from "../content/elements/text/standardHeadline.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Titantron({
  underline,
  secondTitle,
  title,
  height = "full",
  video,
  image,
}) {
  const [videoSizeClass, setVideoSizeClass] = useState(Style.videoWidthBigger);
  const videoElementContainer = useRef();
  const videoElement = useRef();

  useEffect(() => {
    setTimeout(() => {
      // des sorgt dafür, dass das video immer richtig seinen bereich ausfüllt!
      let videoContainerRatio = window.innerWidth / window.innerHeight;
      let videoRatio =
        videoElement.current.clientWidth / videoElement.current.clientHeight;
      setVideoSizeClass(
        videoContainerRatio >= videoRatio
          ? Style.videoWidthBigger
          : Style.videoHeightBigger
      );
    }, 200);
  }, []);

  return (
    <div
      ref={videoElementContainer}
      className={`position-relative overflow-hidden vh-100 w-100`}
    >
      {video && (
        <video
          ref={videoElement}
          autoPlay
          muted
          style={{ zIndex: -1 }}
          loop
          className={videoSizeClass}
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
      {image && <img src={image} />}
      <div
        style={{ zIndex: 1 }}
        className={`${Style.headlineDiv} position-absolute text-light`}
      >
        <StandardHeadline type="h1">{title}</StandardHeadline>
        <StandardHeadline type="h5">{underline}</StandardHeadline>
        <p>{secondTitle}</p>
      </div>
      <div
        className="position-absolute w-100 d-flex align-items-center flex-column"
        style={{
          bottom: "3rem",
          left: 0,
          zIndex: 1,
        }}
      >
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            opacity: 0.5,
            height: "2.5rem",
            width: "2.5rem",
          }}
        />
      </div>
    </div>
  );
}

Titantron.propTypes = {
  underline: PropTypes.string,
  secondTitle: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.any,
  image: PropTypes.any,
};
