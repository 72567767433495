import React from "react";
import Container from "./layout.style.module.scss";
export default function Layout({ children, width, background, className }) {
  return (
    <div
      className={`
      ${Container.layout} 
        ${width === "twothird" ? `${Container.twothird}` : Container.full}
        ${background == "dark" ? "bg-dark text-white" : ""} ${className}
      `}
    >
      {children}
    </div>
  );
}
