import React from "react";
import { Link } from "gatsby";
import Logo from "../../images/logos/mollton-light.png";
import Container from "./layout.style.module.scss";
import StandardButton from "../content/elements/text/standardButton.component";
import { faHeadset, faBars } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { useState } from "react";

export default function Header({ top }) {
  const [open, setOpen] = useState(false);

  const menuItems = [
    <Link key={1} className="p-3 link-light " to={`/referenzen/`}>
      Referenzen
    </Link>,
  ];
  return (
    <header
      className={`w-100 ${Container.window} ${
        Container.layout
      } position-fixed fixed-top ${
        top ? "bg-transparent" : "bg-dark--half"
      } transition-03 blur`}
    >
      <div
        className={`${Container.twothird} flex-column ${
          top ? "bg-transparent" : ""
        } transition-03 pl-5 pr-5 pt-3 pb-3`}
      >
        <div className="d-flex flex-row align-items-center w-100">
          <Link className="flex-shrink-0" to={`/`}>
            <img
              alt="Mollton Veranstaltungstechnik Logo"
              className={
                top
                  ? `${Container.logo_top}`
                  : `
              ${Container.logo_mid}
              `
              }
              src={Logo}
            />
          </Link>
          <div className="ml-auto d-flex align-items-center flex-row">
            {isMobile ? (
              <StandardButton
                className="standardButton--naked standardButton--naked--colorWhite"
                icon={faBars}
                onClick={() => setOpen(!open)}
                onlyIcon={true}
              />
            ) : (
              menuItems
            )}
            <Link to={`/kontakt/`} className="pl-3">
              <StandardButton
                icon={faHeadset}
                onlyIcon={isMobile}
                standardPadding={false}
                className={"standardButton--primary p-3 pl-4 pr-4"}
              >
                Kontakt aufnehmen
              </StandardButton>
            </Link>
          </div>
        </div>
        {open && <div className="d-flex flex-column ml-auto">{menuItems}</div>}
      </div>
      <div
        className={`${Container.redLine} w-100 bg-primary ${
          top ? "bg-transparent" : ""
        } transition-03`}
      ></div>
    </header>
  );
}
