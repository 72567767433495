import React from "react";
import PropTypes from "prop-types";

StandardHeadline.propTypes = {
  type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5"]),
  headlineText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};
StandardHeadline.defaultProps = {
  className: "",
};
/**
 *
 * @param {type, headlineText, className} props
 * @returns
 */
function StandardHeadline(props) {
  let className = "standardHeadline " + props.className;
  switch (props.type) {
    case "h1":
      return (
        <h1 className={`${className} display-1`}>
          {props.headlineText || props.children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={`${className} display-2`}>
          {props.headlineText || props.children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={`${className} display-3`}>
          {props.headlineText || props.children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={`${className} display-4`}>
          {props.headlineText || props.children}
        </h4>
      );
    case "h5":
      return (
        <h5 className={`${className} `}>
          {props.headlineText || props.children}
        </h5>
      );
    default:
      return (
        <h3 className={`${className}`}>
          {props.headlineText || props.children}
        </h3>
      );
  }
}

export default StandardHeadline;
