import React from "react";
import { Link } from "gatsby";
import Logo from "../../images/logos/mollton-light.png";
import Container from "./layout.style.module.scss";
import StandardText from "../content/elements/text/standardText.component";

export default function Footer({ children }) {
  return (
    <footer className={`bg-dark w-100 ${Container.window} ${Container.layout}`}>
      <div className={`${Container.redLine} w-100 bg-primary`}></div>

      <div
        className={`${Container.twothird} pl-5 pr-5 pt-3 pb-3 flex-row align-items-center`}
      >
        <Link className="flex-shrink-0 mr-3" to={`/`}>
          <img
            className={`
              ${Container.logo_bottom}
            `}
            src={Logo}
          />
        </Link>
        <div className="ml-auto d-flex align-items-center flex-wrap">
          <Link key={2} className="mr-3 link-light " to={`/impressum/`}>
            Impressum
          </Link>
          ,
          <StandardText className={"text-white"} type="span">
            <small className="text-muted">handcoded by </small>
            <Link to={"https://www.instagram.com/paynecockson/?hl=de"}>
              Michael Payne
            </Link>
          </StandardText>
        </div>
      </div>
    </footer>
  );
}
