import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "./header.component";
import Footer from "./footer.component";
import Container from "./layout.style.module.scss";

/**
 * Dieser Component ist ein HOC, der auf jeder Page den Content ummanteln muss
 * @param {*} param0
 */
export default function Page({ children, titlePrefix }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const [top, setTop] = useState(true);
  useEffect(() => {
    let scrolling = () => {
      if (window.scrollY === 0) {
        setTop(true);
      }
      if (window.scrollY > 0) {
        setTop(false);
      }
    };
    window.removeEventListener("scroll", scrolling);
    window.addEventListener("scroll", scrolling);
  });
  return (
    <div
      className={` 
    ${Container.window}    
    ${Container.layout}
    overflow-hidden
    `}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>
          {(titlePrefix ? `${titlePrefix} | ` : "") +
            data.site.siteMetadata.title}
        </title>
        <script type="application/ld+json">
          {`
        {
          "@type": "Organization",
          "url": "https://www.mollton-veranstaltungstechnik.de",
          "name": "Mollton Veranstaltungstechnik",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+49 176 97349268",
            "contactType": "Customer Support"
          }
        }
      `}
        </script>
      </Helmet>
      <Header top={top} />
      {children}
      <Footer />
    </div>
  );
}
