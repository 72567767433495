import React from "react";
import PropTypes from "prop-types";

export default function StandardText(props) {
  let returnClass = "standardText " + props.className;
  switch (props.type) {
    case "p":
      return (
        <p className={`${returnClass} ${props.small ? "text-muted" : ""}`}>
          {props.text || props.children}
        </p>
      );
    default:
      return (
        <span className={returnClass}>{props.text || props.children}</span>
      );
  }
}

StandardText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  small: PropTypes.string,
  type: PropTypes.oneOf(["p", "span"]),
};
StandardText.defaultProps = {
  className: "",
  small: false,
  type: "p",
};
